// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZSln1yy2w3CXbGeL1DaR{display:flex;flex-direction:column;justify-content:center;gap:10px;margin-top:16px}@media(min-width: 900px){.ZSln1yy2w3CXbGeL1DaR{margin-top:45px}}.pkfsJEM8FrrEwZcZhRe1{border:1px solid var(--primary-400);border-radius:4px;padding:16px 16px 24px 16px;color:var(--blue-grey-900);background-color:var(--white)}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/CheckoutRollup/CheckoutRollup.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CACA,eAAA,CCIA,yBDTF,sBAQI,eAAA,CAAA,CAIJ,sBACE,mCAAA,CACA,iBAAA,CACA,2BAAA,CACA,0BAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkoutRollup": `ZSln1yy2w3CXbGeL1DaR`,
	"rollupBody": `pkfsJEM8FrrEwZcZhRe1`
};
export default ___CSS_LOADER_EXPORT___;
