// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jgdgJkSvXJs4QDcnzj1N{grid-column-start:1;grid-column-end:5;text-align:center}@media(min-width: 600px){.jgdgJkSvXJs4QDcnzj1N{grid-column-end:9}}@media(min-width: 900px){.jgdgJkSvXJs4QDcnzj1N{grid-column-end:17}}._gD3DlF3bL6OxDqY3Z7e{display:flex;flex-direction:column;gap:16px}@media(min-width: 600px){._gD3DlF3bL6OxDqY3Z7e{gap:8px}}@media(min-width: 900px){._gD3DlF3bL6OxDqY3Z7e{gap:40px}}@media(min-width: 1200px){._gD3DlF3bL6OxDqY3Z7e{gap:32px}}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/ConsolidatedConfirmationPage/ConsolidatedConfirmationPage.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CACA,iBAAA,CACA,iBAAA,CCCA,yBDJF,sBAMI,iBAAA,CAAA,CCGF,yBDTF,sBAUI,kBAAA,CAAA,CAKJ,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CCdA,yBDWF,sBAMI,OAAA,CAAA,CCZF,yBDMF,sBAUI,QAAA,CAAA,CCXF,0BDCF,sBAcI,QAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationMessage": `jgdgJkSvXJs4QDcnzj1N`,
	"confirmationPageContent": `_gD3DlF3bL6OxDqY3Z7e`
};
export default ___CSS_LOADER_EXPORT___;
