// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LZBHhaP0N8CLa7qQJDRp{display:flex;flex-direction:column;gap:12px;padding:16px 24px 24px 24px;background-color:var(--white);border:1px solid var(--primary-400);border-radius:4px}.YlUaShrZm3g1B9gJ7HYY{font:var(--ui-xtra-large-bold);color:var(--blue-grey-900)}.DqkeMMdONnsjEuLtD12u{display:flex;flex-direction:row;gap:8px;width:100%}.DqkeMMdONnsjEuLtD12u>div:first-of-type{flex:1}.PyCEPZxQcG5ZnX__GSFR{display:flex;flex-direction:row;gap:8px;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/DiscountCodesCard/DiscountCodesCard.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,2BAAA,CACA,6BAAA,CACA,mCAAA,CACA,iBAAA,CAGF,sBACE,8BAAA,CACA,0BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CAEA,wCACE,MAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"applyDiscountCodesSection": `LZBHhaP0N8CLa7qQJDRp`,
	"applyDiscountCodeSectionTitle": `YlUaShrZm3g1B9gJ7HYY`,
	"textInputWithButton": `DqkeMMdONnsjEuLtD12u`,
	"discountCodesContainer": `PyCEPZxQcG5ZnX__GSFR`
};
export default ___CSS_LOADER_EXPORT___;
