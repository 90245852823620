// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fmlPjNtQlCi2JglrUZY7{width:320px;overflow:hidden}@media(min-width: 600px){.fmlPjNtQlCi2JglrUZY7{width:240px}}.fmlPjNtQlCi2JglrUZY7 .aHNmTbtOY0QVNDkXE5lK{background-color:var(--blue-grey-50);position:relative;height:8px;border-radius:4px}.fmlPjNtQlCi2JglrUZY7 .SuHI7HfYlVlfOVXzYtyY{display:flex;align-items:center;justify-content:flex-end;margin-top:4px}.fmlPjNtQlCi2JglrUZY7 .SuHI7HfYlVlfOVXzYtyY.gTCILv5XeJMb2hEyqaJF{justify-content:space-between}.fmlPjNtQlCi2JglrUZY7 .SuHI7HfYlVlfOVXzYtyY p{color:var(--blue-grey-600)}.fmlPjNtQlCi2JglrUZY7 .SuHI7HfYlVlfOVXzYtyY svg{fill:var(--green-600)}.fmlPjNtQlCi2JglrUZY7 .Qkwm69w1WwMOVCmIAh5j{display:flex;gap:2px}.fmlPjNtQlCi2JglrUZY7 .Qkwm69w1WwMOVCmIAh5j.MEZhGOPg_gPIKFwzXo7m{flex-direction:row-reverse}.fmlPjNtQlCi2JglrUZY7 .Qkwm69w1WwMOVCmIAh5j .pl3FVSdKMP1ANamAzZXu{display:flex;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/CapacityProgressBar/CapacityProgressBar.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,eAAA,CCEA,yBDJF,sBAKI,WAAA,CAAA,CAGF,4CACE,oCAAA,CACA,iBAAA,CACA,UAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,cAAA,CAEA,iEACE,6BAAA,CAGF,8CACE,0BAAA,CAGF,gDACE,qBAAA,CAIJ,4CACE,YAAA,CACA,OAAA,CAEA,iEACE,0BAAA,CAGF,kEACE,YAAA,CACA,kBAAA,CACA,OAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capacityProgressBar": `fmlPjNtQlCi2JglrUZY7`,
	"progressBar": `aHNmTbtOY0QVNDkXE5lK`,
	"footer": `SuHI7HfYlVlfOVXzYtyY`,
	"hasMinAndMax": `gTCILv5XeJMb2hEyqaJF`,
	"capacity": `Qkwm69w1WwMOVCmIAh5j`,
	"iconRight": `MEZhGOPg_gPIKFwzXo7m`,
	"subtitle": `pl3FVSdKMP1ANamAzZXu`
};
export default ___CSS_LOADER_EXPORT___;
