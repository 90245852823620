// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R6thRy8gBwhYMYg0vETU{display:flex;flex-direction:row;align-items:center;gap:4px;height:24px;width:auto;padding:0 0 0 6px;border-radius:4px;background-color:var(--blue-grey-50)}.O7pXJOoCE8fHEtOSQakB{font:var(--ui-small-regular);line-height:24px;color:var(--blue-grey-900);white-space:nowrap}.BTC7fdh5lfSWYOlD5fKu{all:unset;display:flex;flex-direction:column;justify-content:center;align-items:center;width:24px;height:24px;border-radius:0 4px 4px 0;cursor:pointer}.BTC7fdh5lfSWYOlD5fKu:hover{color:#fff;background-color:var(--red-500)}.BTC7fdh5lfSWYOlD5fKu:focus{color:#fff;background-color:var(--red-500);outline:var(--red-500) solid 2px;outline-offset:1px}`, "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,oCAAA,CAGF,sBACE,4BAAA,CACA,gBAAA,CACA,0BAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,yBAAA,CACA,cAAA,CAEA,4BACE,UAAA,CACA,+BAAA,CAGF,4BACE,UAAA,CACA,+BAAA,CACA,gCAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `R6thRy8gBwhYMYg0vETU`,
	"tagLabel": `O7pXJOoCE8fHEtOSQakB`,
	"closeButton": `BTC7fdh5lfSWYOlD5fKu`
};
export default ___CSS_LOADER_EXPORT___;
