// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TUjLj6qOJSKDooefl8zG{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:8px;margin-bottom:12px}@media(min-width: 900px){.TUjLj6qOJSKDooefl8zG{flex-direction:row;margin-bottom:8px;align-items:flex-start}}.X0PUTn2WAHFYwmTBsSrC,.pz9Z7oRyX59ANge1pETT{grid-column:1/span 2}@media(min-width: 900px){.X0PUTn2WAHFYwmTBsSrC,.pz9Z7oRyX59ANge1pETT{grid-column:auto}}.pz9Z7oRyX59ANge1pETT{display:flex;flex-direction:column;gap:4px}.UChdUjrQdmly4O93yzxn{display:flex;flex-direction:row;align-items:flex-start;gap:8px;width:100%}.UChdUjrQdmly4O93yzxn>div:first-of-type{flex:1}.UChdUjrQdmly4O93yzxn>div:nth-of-type(2){margin-top:24px}.OTr1Q7nYYVPVlj52ABPS{font:var(--ui-small-regular)}.ARyT0oUNcwJnm2K8sNK5{display:flex;flex-direction:row;gap:16px}.ARyT0oUNcwJnm2K8sNK5>div>a{color:var(--secondary-600)}`, "",{"version":3,"sources":["webpack://./src/components/RegistrationInfoCard/RegistrationInfoCard.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,+CAAA,CACA,OAAA,CACA,kBAAA,CCKA,yBDTF,sBAOI,kBAAA,CACA,iBAAA,CACA,sBAAA,CAAA,CAIJ,4CACE,oBAAA,CCLA,yBDIF,4CAII,gBAAA,CAAA,CAIJ,sBAEE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,UAAA,CAEA,wCACE,MAAA,CAGF,yCACE,eAAA,CAIJ,sBACE,4BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4BACE,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroupContainer": `TUjLj6qOJSKDooefl8zG`,
	"inputContainer": `X0PUTn2WAHFYwmTBsSrC`,
	"membershipVerificationContainer": `pz9Z7oRyX59ANge1pETT`,
	"textInputWithButton": `UChdUjrQdmly4O93yzxn`,
	"membershipVerificationHelperText": `OTr1Q7nYYVPVlj52ABPS`,
	"membershipVerificationHelperLinks": `ARyT0oUNcwJnm2K8sNK5`
};
export default ___CSS_LOADER_EXPORT___;
