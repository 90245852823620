// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R0rnOIwCm3stTg3GNsaQ{display:flex;flex-direction:column;padding:16px 0 0}.R0rnOIwCm3stTg3GNsaQ .ewbmsgELLs_8XtZdvBNh{display:flex;align-items:flex-start}@media(min-width: 1200px){.R0rnOIwCm3stTg3GNsaQ .ewbmsgELLs_8XtZdvBNh{align-items:center}}.R0rnOIwCm3stTg3GNsaQ .Zp5VXgILm_TvhCSK7Dmy{font:var(--ui-large-regular);color:var(--blue-grey-900);margin-left:8px}.R0rnOIwCm3stTg3GNsaQ .Zp5VXgILm_TvhCSK7Dmy span{font:var(--ui-large-bold);color:var(--secondary-600);cursor:pointer;text-decoration:underline}.R0rnOIwCm3stTg3GNsaQ .Zp5VXgILm_TvhCSK7Dmy span:hover{color:var(--secondary-700)}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/TermsAndConditionsAgreementBox/TermsAndConditionsAgreementBox.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CCOF,0BDTA,4CAKI,kBAAA,CAAA,CAIJ,4CACE,4BAAA,CACA,0BAAA,CACA,eAAA,CAEA,iDACE,yBAAA,CACA,0BAAA,CACA,cAAA,CACA,yBAAA,CAEA,uDACE,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsAndConditionsAgreementBoxContainer": `R0rnOIwCm3stTg3GNsaQ`,
	"termsAndConditionsAgreementBox": `ewbmsgELLs_8XtZdvBNh`,
	"label": `Zp5VXgILm_TvhCSK7Dmy`
};
export default ___CSS_LOADER_EXPORT___;
