// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaIdJ6beKeTypGECAuP4{display:flex;flex-direction:column;gap:8px}.gaIdJ6beKeTypGECAuP4 .ri5ppnK0Q7snWYNcQnNX{display:flex;flex-direction:row;justify-content:space-between;align-items:center;gap:16px;height:24px;font:var(--headline-xtra-small)}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4{display:flex;flex-direction:column;padding:12px 0 8px 0;border-top:1px solid var(--blue-grey-200)}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4 .I1VXRaqgO6HjZz5cWezA{display:flex;flex-direction:row;align-items:center;gap:8px;font:var(--ui-large-regular)}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4.nMedJ6rtOaHZ7Ngb6nyj .fXrBqY01CAeqKp4rUHQ4{display:flex;flex-direction:column;gap:4px}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4 .fXrBqY01CAeqKp4rUHQ4{display:none;margin:12px 0 0 30px}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4 .fXrBqY01CAeqKp4rUHQ4 .Pa5uS3RkO67ZzM0Timw5{display:flex;flex-direction:row;align-items:center;gap:8px;font:var(--ui-small-regular);line-height:1}.gaIdJ6beKeTypGECAuP4 .TY7d8y9XQDeKD0cOFXM4 .fXrBqY01CAeqKp4rUHQ4 .Pa5uS3RkO67ZzM0Timw5 .DEFJYw8usmi7hpFK3zvx{display:flex;flex-direction:row;align-items:center;gap:4px;font:var(--ui-small-bold);line-height:1}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/PaymentPlansModal/PaymentPlansModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,WAAA,CACA,+BAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,yCAAA,CAEA,kEACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,4BAAA,CAIA,uFACE,YAAA,CACA,qBAAA,CACA,OAAA,CAIJ,kEACE,YAAA,CACA,oBAAA,CAEA,wFACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,4BAAA,CACA,aAAA,CAEA,8GACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,yBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentPlanModalBody": `gaIdJ6beKeTypGECAuP4`,
	"paymentPlanModalBodyHead": `ri5ppnK0Q7snWYNcQnNX`,
	"paymentPlanSummary": `TY7d8y9XQDeKD0cOFXM4`,
	"paymentPlanName": `I1VXRaqgO6HjZz5cWezA`,
	"selectedPlan": `nMedJ6rtOaHZ7Ngb6nyj`,
	"paymentPlanInstallments": `fXrBqY01CAeqKp4rUHQ4`,
	"paymentPlanInstallment": `Pa5uS3RkO67ZzM0Timw5`,
	"installmentDueDate": `DEFJYw8usmi7hpFK3zvx`
};
export default ___CSS_LOADER_EXPORT___;
