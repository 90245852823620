// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZlAj0EkgmSP9Tf8lLmmB{background:var(--primary-35);padding:16px 24px;border-top:1px solid var(--primary-100)}@media(min-width: 600px){.ZlAj0EkgmSP9Tf8lLmmB{margin-top:16px}}.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU{list-style:none;display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:12px 16px;margin:0;padding:0}@media(min-width: 600px){.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU{grid-template-columns:repeat(3, minmax(0, 1fr))}}@media(min-width: 1200px){.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU{grid-template-columns:repeat(6, minmax(0, 1fr))}}.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU .mHGSgYkNR8gRcYBz9aY4{flex-basis:0;flex-grow:1;margin:0;padding:0}.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU .mHGSgYkNR8gRcYBz9aY4 .DHyznJzSKVreSCuwbATt span{white-space:nowrap}.ZlAj0EkgmSP9Tf8lLmmB .AREZ8DGmsdUiipjztopU .mHGSgYkNR8gRcYBz9aY4 .DHyznJzSKVreSCuwbATt span svg{stroke:var(--blue-grey-900);display:inline-block;margin:0 0 -2px 4px}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/TournamentDetailsPage/DetailsCard/DetailsCard.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,4BAAA,CACA,iBAAA,CACA,uCAAA,CCCA,yBDJF,sBAKI,eAAA,CAAA,CAEF,4CACE,eAAA,CACA,YAAA,CACA,+CAAA,CACA,aAAA,CACA,QAAA,CACA,SAAA,CCTF,yBDGA,4CAQI,+CAAA,CAAA,CCDJ,0BDPA,4CAWI,+CAAA,CAAA,CAEF,kEACE,YAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CAEE,6FACE,kBAAA,CACA,iGACE,2BAAA,CACA,oBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsCard": `ZlAj0EkgmSP9Tf8lLmmB`,
	"details": `AREZ8DGmsdUiipjztopU`,
	"detail": `mHGSgYkNR8gRcYBz9aY4`,
	"link": `DHyznJzSKVreSCuwbATt`
};
export default ___CSS_LOADER_EXPORT___;
