// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.5__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BF2GZZpy9vbxJse8HPdw{display:flex;flex-direction:row;align-items:flex-start;gap:12px;padding:16px;border-radius:8px;font:var(--ui-medium-large);line-height:24px;color:var(--blue-grey-900)}.BF2GZZpy9vbxJse8HPdw ._8i013Tq_9ActgvbUalm{min-width:24px;height:24px}.BF2GZZpy9vbxJse8HPdw .ShcLh9c28WsUb7vX8QLI{min-width:16px;height:16px}.BF2GZZpy9vbxJse8HPdw .oOfkhkxRf6HfTUcB3Wf8 a{font-weight:700}.BF2GZZpy9vbxJse8HPdw.Df5sNDFjSXy_rsDXUlaZ{border:1px solid var(--red-500);background:var(--red-25)}.BF2GZZpy9vbxJse8HPdw.Df5sNDFjSXy_rsDXUlaZ a{color:var(--red-400)}.BF2GZZpy9vbxJse8HPdw.p7522cLtvB4p8of_pMCl{border:1px solid var(--blue-500, #3679fa);background:var(--blue-25, #f5f8ff)}.BF2GZZpy9vbxJse8HPdw.p7522cLtvB4p8of_pMCl a{color:var(--blue-700, #2655af)}.BF2GZZpy9vbxJse8HPdw.p_aWmTwJ4NlEB3e4bHsA{border:1px solid var(--green-500);background:var(--green-25)}.BF2GZZpy9vbxJse8HPdw.p_aWmTwJ4NlEB3e4bHsA a{color:var(--green-700)}.BF2GZZpy9vbxJse8HPdw._FKeF9tF6dCglzRg78Kd{border:1px solid var(--orange-700, #df6c06);background:var(--orange-50, #fef0e4)}.BF2GZZpy9vbxJse8HPdw._FKeF9tF6dCglzRg78Kd a{color:var(--orange-900, #ae5404)}`, "",{"version":3,"sources":["webpack://./src/components/PageBanner/PageBanner.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,YAAA,CACA,iBAAA,CACA,2BAAA,CACA,gBAAA,CACA,0BAAA,CAEA,4CACE,cAAA,CACA,WAAA,CAGF,4CACE,cAAA,CACA,WAAA,CAGF,8CACE,eAAA,CAGF,2CACE,+BAAA,CACA,wBAAA,CAEA,6CACE,oBAAA,CAIJ,2CACE,yCAAA,CACA,kCAAA,CAEA,6CACE,8BAAA,CAIJ,2CACE,iCAAA,CACA,0BAAA,CAEA,6CACE,sBAAA,CAIJ,2CACE,2CAAA,CACA,oCAAA,CAEA,6CACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBanner": `BF2GZZpy9vbxJse8HPdw`,
	"typeIcon": `_8i013Tq_9ActgvbUalm`,
	"closeIcon": `ShcLh9c28WsUb7vX8QLI`,
	"textContent": `oOfkhkxRf6HfTUcB3Wf8`,
	"error": `Df5sNDFjSXy_rsDXUlaZ`,
	"informative": `p7522cLtvB4p8of_pMCl`,
	"success": `p_aWmTwJ4NlEB3e4bHsA`,
	"warning": `_FKeF9tF6dCglzRg78Kd`
};
export default ___CSS_LOADER_EXPORT___;
